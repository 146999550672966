<template>
  <div>
    <!--Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="1200">
      <v-card flat class="white">
        <v-card-title class="text-h5 secondary--text"
          >Teacher Review Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" lazy-validation>
            <v-container fluid>
              <v-row dense>
                <v-col cols="12" sm="12">
                  <!--Teacher Review Dates-->
                  <v-row dense>
                    <v-col cols="12" sm="12" md="4">
                      <text-display
                        :displayLabel="'Teacher Review Date'"
                        :displayString="
                          $_monthNumToText(teacherReviewStore.entry.f_month) +
                            ' - ' +
                            teacherReviewStore.entry.d_year
                        "
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Teacher ID-->
                    <v-col cols="12" sm="12" md="2">
                      <text-display
                        :displayLabel="'Teacher ID'"
                        :displayNumber="teacherReviewStore.entry.fk_teacherID"
                      />
                    </v-col>
                    <!--Teacher Name-->
                    <v-col cols="12" sm="12" md="6">
                      <text-display
                        :displayLabel="'Teacher Name'"
                        :displayString="teacherReviewStore.entry.d_teacherName"
                      />
                    </v-col>
                    <!--Years Of Experience-->
                    <v-col cols="12" sm="12" md="4">
                      <text-display
                        :displayLabel="'Years of Experience'"
                        :displayString="teacherReviewStore.entry.d_yearsExperience"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Provider Name-->
                    <v-col cols="12" sm="12" md="8">
                      <text-display
                        :displayLabel="'Provider Name'"
                        :displayString="teacherReviewStore.entry.d_providerNameLicense"
                      />
                    </v-col>

                    <!--Provider Name-->
                    <v-col cols="12" sm="12" md="3">
                      <text-display
                        :displayLabel="'Provider PreK Setting'"
                        :displayString="teacherReviewStore.entry.providerPreKSetting"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row dense>
                <!--Left Side-->
                <v-col cols="12" sm="12" md="8">
                  <v-row dense>
                    <!--Classroom Name-->
                    <v-col cols="12" sm="12" md="4">
                      <text-display
                        :displayLabel="'Classroom Name'"
                        :displayString="teacherReviewStore.entry.d_classroomName"
                      />
                    </v-col>
                    <!--BK Status-->
                    <v-col cols="12" sm="12" md="4">
                      <text-display
                        :displayLabel="'BK Status'"
                        :displayString="$_yesNo(teacherReviewStore.entry.f_bk)"
                      />
                    </v-col>
                    <!--Classroom Start Date-->
                    <v-col cols="12" sm="12" md="4">
                      <text-display
                        :displayLabel="'Classroom Start Date'"
                        :displayString="teacherReviewStore.entry.d_startDate"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--License Issue Date-->
                    <v-col cols="12" sm="12" md="4">
                      <text-display
                        :displayLabel="'License Issue Date'"
                        :displayString="teacherReviewStore.entry.d_licenseIssueDate"
                      />
                    </v-col>
                    <!--License Expiration Date-->
                    <v-col cols="12" sm="12" md="4">
                      <text-display
                        :displayLabel="'License Expiration Date'"
                        :displayString="teacherReviewStore.entry.d_licenseExpirationDate"
                      />
                    </v-col>
                    <!--License Type-->
                    <v-col cols="12" sm="12" md="4">
                      <text-display
                        :displayLabel="'License Type'"
                        :displayString="teacherReviewStore.entry.licenseType"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Adequate -->
                    <v-col cols="12" sm="12" md="4">
                      <text-display
                        :displayLabel="'Adequate'"
                        :displayString="$_yesNo(teacherReviewStore.entry.f_adequate)"
                      />
                    </v-col>
                    <!--Monthly Salary Check -->
                    <v-col cols="12" sm="12" md="4">
                      <text-display
                        :displayLabel="'Last Monthly Salary Check'"
                        :displayString="
                          $_monthNumToText(teacherReviewStore.entry.f_monthSalaryCheck)
                        "
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Confirmation Form Received-->
                    <v-col cols="12" sm="4">
                      <v-select
                        :items="valueListsStore.yesNo"
                        item-text="value"
                        item-value="id"
                        label="Confirmation Form Received"
                        placeholder=" "
                        persistent-placeholder
                        v-model="f_confirmationFormReceived"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Follow Up Reason-->
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        :items="valueListsStore.valueListItems[35]"
                        label="Follow Up Reason"
                        placeholder=" "
                        persistent-placeholder
                        item-text="d_name"
                        item-value="id"
                        v-model="f_followup"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Status-->
                    <v-col cols="12" sm="6">
                      <v-select
                        v-if="usersStore.authUser.f_teacherReviewStatus === 1"
                        :items="valueListsStore.teacherReviewStatus"
                        item-text="value"
                        item-value="id"
                        label="Status"
                        placeholder=" "
                        persistent-placeholder
                        v-model="f_status"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                      <text-display
                        v-else
                        :displayLabel="'Status'"
                        :displayString="$_teacherReviewStatus(f_status)"
                      />
                    </v-col>

                    <!--Approved Timestamp-->
                    <v-col cols="12" sm="6">
                      <text-display v-if="f_status === 3 && d_dateApproved"
                        :displayLabel="'Date Approved'"
                        :displayString="$_formatTimestamp(d_dateApproved)"
                      />
                      <text-display v-else
                        :displayLabel="'Date Approved'"
                        :displayString="''"
                      />
                    </v-col>
                  </v-row>

                  <v-row class="pb-4">
                    <v-col cols="12" sm="12">
                      <span class="text-caption grey--text"
                        >Created: {{ $_formatTimestamp(created_at) }} &#8226; Modified:
                        {{ $_formatTimestamp(updated_at) }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>

                <v-divider vertical />

                <!--Right Side-->
                <v-col cols="12" sm="12" md="3">
                  <v-container>
                    <v-row dense>
                      <!--Internal Note-->
                      <v-col cols="12" sm="12">
                        <v-textarea
                          v-model="d_noteInternal"
                          label="Internal Note"
                          placeholder=" "
                          persistent-placeholder
                        />
                      </v-col>
                    </v-row>

                    <v-row dense>
                      <!--External Note-->
                      <v-col cols="12" sm="12">
                        <v-textarea
                          v-model="d_noteExternal"
                          label="External Note"
                          placeholder=" "
                          persistent-placeholder
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>

              <v-row dense> </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TextDisplay from "@/components/TextDisplay";

const { mapFields } = createHelpers({
  getterType: "teacherReviewStore/getEntryField",
  mutationType: "teacherReviewStore/updateEntryField"
});

export default {
  name: "TeacherReviewEntry",
  mixins: [mixin],
  computed: {
    ...mapState({
      teacherReviewStore: state => state.teacherReviewStore,
      valueListsStore: state => state.valueListsStore,
      usersStore: state => state.usersStore
    }),
    ...mapFields([
      "show",
      "valid",
      "mode",
      "id",
      "fk_providerID",
      "fk_classroomHistoryID",
      "fk_teacherID",
      "fk_teacherLicenseID",
      "d_providerNameLicense",
      "d_classroomNumber",
      "d_classroomName",
      "f_month",
      "d_year",
      "d_teacherName",
      "f_bk",
      "d_startDate",
      "d_yearsExperience",
      "d_licenseIssueDate",
      "d_licenseExpirationDate",
      "d_licenseType",
      "f_adequate",
      "f_monthSalaryCheck",
      "f_licenseEligibility",
      "f_paystubSubmitted",
      "f_supplementAuthorized",
      "d_supplementAmount",
      "d_noteExternal",
      "d_noteInternal",
      "d_dateApproved",
      "f_confirmationFormReceived",
      "f_followup",
      "f_status",
      "f_show",
      "created_at",
      "updated_at"
    ])
  },
  components: {
    TextDisplay
  },
  data() {
    return {
      menu: false
    };
  },

  methods: {
    resetEntry() {
      this.$store.dispatch("teacherReviewStore/resetEntry");
    },

    cancelEntry() {
      this.show = false;
      this.resetEntry();
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters["teacherReviewStore/getEntry"];
        await this.$store.dispatch("teacherReviewStore/update", entry);
        this.cancelEntry();
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
