<template>
  <div>
    <!--Title Bar-->
    <title-bar v-if="teacherReviewSection === 1" :title-value="'Teacher Reviews'"></title-bar>

    <!-- Title Bar For Section List-->
    <v-card v-else flat>
      <v-toolbar dense flat color="secondary" class="mb-2" dark>
        <v-icon>fal fa-clipboard-list-check</v-icon>
        <v-toolbar-title class="pl-2">Teacher Reviews</v-toolbar-title>
      </v-toolbar>
    </v-card>

    <!-- Filter -->
    <v-toolbar flat dense>
      <v-menu v-model="filterMenu" offset-y right :close-on-content-click="false">
        <template #activator="{ on }">
          <v-btn rounded color="primary" v-on="on"
            ><v-icon small left>fal fa-filter</v-icon><span v-if="showFiltered()">Filtered</span
            ><span v-else>Filter</span>
          </v-btn>
        </template>

        <v-card width="700px">
          <v-card-text>
            <v-container fluid grid-list-sm>
              <v-row dense>
                <v-col cols="12" sm="12" v-if="showFiltered()">
                  <v-btn block rounded color="primary" @click="clearFilter()">
                    <v-icon small left>fal fa-times</v-icon>Clear Filters
                  </v-btn>
                </v-col>
              </v-row>

              <v-row dense>
                <!--Left Side-->
                <v-col cols="12" sm="12" md="6">
                  <v-row v-if="teacherReviewSection !== 2" dense>
                    <!--Provider License-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="d_licenseNumber"
                        label="Provider License #"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="teacherReviewSection !== 2" dense>
                    <!--Provider Name-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="d_nameLicense"
                        label="Provider Name"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="teacherReviewSection !== 3" dense>
                    <!--Teacher ID-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="teacherID"
                        label="Teacher ID"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="teacherReviewSection !== 3" dense>
                    <!--Teacher First Name-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="d_nameFirst"
                        label="Teacher First Name"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row v-if="teacherReviewSection !== 3" dense>
                    <!--Teacher Last Name-->
                    <v-col cols="12" sm="12">
                      <v-text-field
                        v-model="d_nameLast"
                        label="Teacher Last Name"
                        placeholder=" "
                        persistent-placeholder
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Follow Up-->
                    <v-col cols="12" sm="12">
                      <v-autocomplete
                        :items="valueListsStore.valueListItems[35]"
                        item-text="d_name"
                        item-value="id"
                        label="Follow Up Reason"
                        placeholder=" "
                        persistent-placeholder
                        v-model="f_followup"
                        key="id"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--No Follow Up-->
                    <v-col class="pt-0 pb-4" cols="12" sm="12">
                      <label class="grey--text text--darken-1 text-caption mb-0 pb-0"
                        >No Follow Up Required</label
                      >
                      <v-checkbox
                        v-model="noFollowup"
                        label=""
                        value="Yes"
                        color="primary"
                        class="pr-2 mt-1 pt-0"
                        hide-details
                      >
                      </v-checkbox>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Status-->
                    <v-col cols="12" sm="12">
                      <v-select
                        :items="valueListsStore.teacherReviewStatus"
                        item-text="value"
                        item-value="id"
                        label="Teacher Review Status"
                        placeholder=" "
                        persistent-placeholder
                        v-model="f_status"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Teacher Review Approval Date-->
                    <v-col cols="12" sm="12">
                      <v-menu
                        :close-on-content-click="false"
                        v-model="menu5"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template #activator="{ on }">
                          <v-text-field
                            v-on="on"
                            v-model="d_dateApproved"
                            label="Approval Date (>= date entered)"
                            placeholder=" "
                            persistent-placeholder
                            prepend-icon="fal fa-calendar-alt"
                            required
                            @blur="date5 = $_parseDate(d_dateApproved)"
                          />
                        </template>
                        <v-date-picker
                          v-model="date5"
                          @input="menu5 = false"
                          color="primary"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>

                <!--Right Side-->
                <v-col cols="12" sm="12" md="6">
                  <v-row dense>
                    <!--Month-->
                    <v-col cols="12" sm="7">
                      <v-select
                        :items="valueListsStore.months"
                        item-text="value"
                        item-value="id"
                        label="Review Month"
                        placeholder=" "
                        persistent-placeholder
                        v-model="f_month"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>

                    <!--Year-->
                    <v-col cols="12" sm="5">
                      <v-select
                        :items="yearsStore.yearsValueList"
                        item-text="d_yearStart"
                        item-value="d_yearStart"
                        label="Review Year"
                        placeholder=" "
                        persistent-placeholder
                        v-model="d_year"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <!--Provider PreK Setting-->
                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <v-select
                        :items="valueListsStore.valueListItems[27]"
                        item-text="d_name"
                        item-value="d_name"
                        label="Provider Prek Setting"
                        placeholder=" "
                        persistent-placeholder
                        v-model="providerPrekSetting"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <!--License Type-->
                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <v-select
                        :items="valueListsStore.valueListItems[30]"
                        item-text="d_name"
                        item-value="d_value"
                        label="License Type"
                        placeholder=" "
                        persistent-placeholder
                        v-model="d_licenseType"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--Range Start Date Of License Expiration-->
                    <v-col cols="12" sm="6">
                      <v-menu
                        :close-on-content-click="false"
                        v-model="menu3"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template #activator="{ on }">
                          <v-text-field
                            v-on="on"
                            v-model="dateStart"
                            label="License Expiration"
                            placeholder="Start Date"
                            persistent-placeholder
                            prepend-icon="fal fa-calendar-alt"
                            required
                            @blur="date3 = $_parseDateMonth(dateStart)"
                          />
                        </template>
                        <v-date-picker
                          type="month"
                          v-model="date3"
                          @input="menu3 = false"
                          color="primary"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>

                    <!--Range End Date of License Expiration-->
                    <v-col cols="12" sm="6">
                      <v-menu
                        :close-on-content-click="false"
                        v-model="menu4"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template #activator="{ on }">
                          <v-text-field
                            v-on="on"
                            v-model="dateEnd"
                            label="License Expiration"
                            placeholder="End Date"
                            persistent-placeholder
                            prepend-icon="fal fa-calendar-alt"
                            required
                            @blur="date4 = $_parseDateMonth(dateEnd)"
                          />
                        </template>
                        <v-date-picker
                          type="month"
                          v-model="date4"
                          @input="menu4 = false"
                          color="primary"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <!--No License-->
                    <v-col class="pt-0 pb-4" cols="12" sm="12">
                      <label class="grey--text text--darken-1 text-caption mb-0 pb-0"
                        >Had No License</label
                      >
                      <v-checkbox
                        v-model="noLicense"
                        label=""
                        value="Yes"
                        color="primary"
                        class="pr-2 mt-1 pt-0"
                        hide-details
                      >
                      </v-checkbox>
                    </v-col>
                  </v-row>

                  <!--Adequate-->
                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <v-select
                        :items="valueListsStore.yesNo"
                        item-text="value"
                        item-value="id"
                        label="Adequate"
                        placeholder=" "
                        persistent-placeholder
                        v-model="f_adequate"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <!--Last Salary Check Month-->
                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <v-select
                        :items="valueListsStore.months"
                        item-text="value"
                        item-value="id"
                        label="Last Salary Check"
                        placeholder=" "
                        persistent-placeholder
                        v-model="f_monthSalaryCheck"
                        clearable
                        clear-icon="fal fa-times-circle"
                      />
                    </v-col>
                  </v-row>

                  <!--Teacher Has Alert-->
                  <v-row dense>
                    <v-col class="pt-0 pb-4" cols="12" sm="12">
                      <label class="grey--text text--darken-1 text-caption mb-0 pb-0"
                        >Teacher Has Alert</label
                      >
                      <v-checkbox
                        v-model="alert"
                        label=""
                        value="Yes"
                        color="primary"
                        class="pr-2 mt-1 pt-0"
                        hide-details
                      >
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn block rounded color="primary" @click="filter()">
              <v-icon small left>fal fa-check</v-icon>Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <v-spacer />

      <teacher-review-entry />

      <export-excel
        :section="44"
        :enabled="
          teacherReviewStore.reviewItems.data && teacherReviewStore.reviewItems.data.length > 0
        "
      />

      <teacher-review-mass-change />
    </v-toolbar>

    <!--Pagination  -->
    <pagination
      :show="teacherReviewStore.reviewItems.data && teacherReviewStore.reviewItems.length > 0"
      :showTotal="true"
      :currentPage="teacherReviewStore.reviewItems.current_page"
      :lastPage="teacherReviewStore.reviewItems.last_page"
      :total="teacherReviewStore.reviewItems.total"
      v-on:paginate-event="filter($event)"
    />

    <div v-if="progressStore.progress !== true">
      <!--Table-->
      <v-simple-table
        v-if="teacherReviewStore.reviewItems.data && teacherReviewStore.reviewItems.data.length > 0"
        :fixed-header="true"
        class="my-2"
      >
        <template #default>
          <thead>
            <tr>
              <th class="text-left grey--text text--darken-1">Teacher ID</th>
              <th class="text-left grey--text text--darken-1">Teacher</th>
              <th class="text-left grey--text text--darken-1">Classroom Name</th>
              <th class="text-left grey--text text--darken-1">BK Status</th>
              <th class="text-left grey--text text--darken-1">Confirmation Form</th>
              <th class="text-left grey--text text--darken-1">Years Of Experience</th>
              <th class="text-left grey--text text--darken-1">Type Of License</th>
              <th class="text-left grey--text text--darken-1">License Expiration</th>
              <th class="text-left grey--text text--darken-1">Adequate</th>
              <th class="text-left grey--text text--darken-1">Last Salary Check</th>
              <th class="text-left grey--text text--darken-1">Provider PreK Setting</th>
              <th class="text-left grey--text text--darken-1">Alert</th>
              <th class="text-left grey--text text--darken-1">Follow Up</th>
              <th class="text-right grey--text text--darken-1">Status</th>
              <th>
                <v-btn rounded @click="selectDeselectAll()" color="primary" class="py-0"
                  ><v-icon small left>fal fa-check-double</v-icon>All</v-btn
                >
              </th>
            </tr>
          </thead>
          <tbody v-for="(item, index) in teacherReviewStore.reviewItems.data" :key="item.id">
            <tr v-if="item.header">
              <th class="text-subtitle-2 text-left grey white--text" colspan="16">
                <span class="pr-4">{{ item.header }}</span>
                <v-tooltip v-if="teacherReviewSection === 1" top color="primary">
                  <template #activator="{ on }">
                    <v-btn icon v-on="on" @click="detail(1, item.provider.uuid)">
                      <v-icon color="white">
                        fal fa-arrow-alt-square-right
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Go To Provider</span>
                </v-tooltip>
              </th>
            </tr>
            <tr class="clickable">
              <!--1 - Teacher ID-->
              <td
                @click="openEntry(item.id)"
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ item.fk_teacherID }}
              </td>
              <!--2 - Teacher Name-->
              <td
                @click="openEntry(item.id)"
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ item.d_teacherName }}
              </td>
              <!--3 - Classroom Name-->
              <td
                @click="openEntry(item.id)"
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ item.d_classroomName }}
              </td>
              <!--4 - BK Status-->
              <td
                @click="openEntry(item.id)"
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ $_yesNo(item.f_bk) }}
              </td>
              <!--5 - BK Status-->
              <td
                @click="openEntry(item.id)"
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ $_yesNo(item.f_confirmationFormReceived) }}
              </td>
              <!--6 - Years Of Experience-->
              <td
                @click="openEntry(item.id)"
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ item.d_yearsExperience }}
              </td>
              <!--7 - Type Of License-->
              <td
                @click="openEntry(item.id)"
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ item.license_type ? item.license_type.d_name : '' }}
              </td>
              <!--8 - License Expiration-->
              <td
                @click="openEntry(item.id)"
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ $_formatDate(item.d_licenseExpirationDate) }}
              </td>
              <!--9 - Adequate-->
              <td
                @click="openEntry(item.id)"
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ $_yesNo(item.f_adequate) }}
              </td>
              <!--10 - Last Salary Check-->
              <td
                @click="openEntry(item.id)"
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ $_monthNumToText(item.f_monthSalaryCheck) }}
              </td>
              <!--11 - Provider PreK Setting-->
              <td
                @click="openEntry(item.id)"
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ item.provider ? item.provider.j_prekSetting.join(", ") : ''}}
              </td>

              <!--12 - Alert-->
              <td
                @click="openEntry(item.id)"
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                <v-icon v-if="item.teacher.actions && item.teacher.actions.length > 0" left medium
                  >fal fa-exclamation-triangle</v-icon
                >
              </td>
              <!--13 - Follow Up-->
              <td
                @click="openEntry(item.id)"
                class="text-left light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                <v-icon v-if="item.f_followup" left medium>fal fa-comment-alt-check</v-icon>
              </td>
              <!--14 - Status-->
              <td
                @click="openEntry(item.id)"
                class="text-right light-blue--text text--darken-4"
                :class="item.followup ? item.followup.d_color : ''"
              >
                {{ $_teacherReviewStatus(item.f_status) }}
              </td>
              <!--15 - Go To Teacher-->
              <td :class="item.followup ? item.followup.d_color : ''">
                <v-layout align-center justify-space-around>
                  <v-checkbox
                    dense
                    :key="index"
                    v-model="selectedID"
                    :value="item.id"
                    color="primary"
                    multiple
                    class="py-2"
                  ></v-checkbox>
                  <v-tooltip top color="primary" v-if="teacherReviewSection !== 3">
                    <template #activator="{ on }">
                      <v-btn icon v-on="on" @click="detail(2, item.teacher.uuid)">
                        <v-icon color="primary">
                          fal fa-arrow-alt-square-right
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Go To Teacher</span>
                  </v-tooltip>
                </v-layout>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-list v-else class="py-2">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="error--text text-subtitle-1 text-xs-center"
              >No Teacher Reviews Exist</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";
import { mixin } from "@/mixins/mixin.js";
import TitleBar from "@/components/TitleBar";
import Pagination from "@/components/Pagination";
import TeacherReviewMassChange from "@/components/TeacherReviewMassChange";
import ExportExcel from "@/components/ExportExcel";
import TextDisplay from "@/components/TextDisplay";
import TeacherReviewEntry from "@/components/TeacherReviewEntry";
import ReportDatePicker from "@/components/ReportDatePicker";

const { mapFields } = createHelpers({
  getterType: "teacherReviewStore/getFilterField",
  mutationType: "teacherReviewStore/updateFilterField"
});

export default {
  name: "TeacherReviews",
  components: {
    TitleBar,
    Pagination,
    TeacherReviewMassChange,
    ExportExcel,
    TextDisplay,
    TeacherReviewEntry,
    ReportDatePicker
  },
  mixins: [mixin],
  computed: {
    ...mapState({
      teacherReviewStore: state => state.teacherReviewStore,
      yearsStore: state => state.yearsStore,
      valueListsStore: state => state.valueListsStore,
      progressStore: state => state.progressStore,
      programsStore: state => state.programsStore,
      usersStore: state => state.usersStore
    }),
    ...mapFields([
      "page",
      "section",

      "fk_providerID",
      "fk_teacherID",
      "teacherID",
      "d_licenseNumber",
      "d_nameLicense",
      "d_nameFirst",
      "d_nameLast",
      "f_month",
      "d_year",
      "f_followup",
      "f_status",
      "noFollowup",
      "f_adequate",
      "d_licenseType",
      "noLicense",
      "selectedID",
      "fk_reportID",
      "dateStart",
      "dateEnd",
      "date3",
      "date4",
      "f_monthSalaryCheck",
      "alert",
      "providerPrekSetting",
      "d_dateApproved",
      "date5"
    ])
  },
  props: {
    /* All List = 1, Provider Sublist = 2, Teacher Sublist = 3 */
    teacherReviewSection: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      menu3: false,
      menu4: false,
      menu5: false,
      filterMenu: false
    };
  },
  created() {
    this.initialize();
  },
  watch: {
    date3() {
      this.dateStart = this.$_formatDateMonth(this.date3);
    },
    date4() {
      this.dateEnd = this.$_formatDateMonth(this.date4);
    },
    date5() {
      this.d_dateApproved = this.$_formatDate(this.date5);
    }
  },
  methods: {
    async initialize() {
      if (this.teacherReviewSection === 1) {
        this.$store.dispatch("progressStore/set", true);
      }

      await this.changeSectionCheck();

      /* Provider List */
      if (this.teacherReviewSection === 2) {
        const provider = this.$store.getters["providersStore/getProvider"];
        this.fk_providerID = provider.id;
        this.fk_teacherID = "";
      } else if (this.teacherReviewSection === 3) {
        /* Teacher List */
        const teacher = this.$store.getters["teachersStore/getTeacher"];
        this.fk_providerID = "";
        this.fk_teacherID = teacher.id;
      } else {
        /* All list */
        this.fk_providerID = "";
        this.fk_teacherID = "";
      }

      const user = this.$store.getters["usersStore/getAuthUser"];

      /* Grab filters */
      const filters = this.$store.getters["teacherReviewStore/getFilters"];
      /* Records based on filters */
      await this.$store.dispatch("teacherReviewStore/fetch", filters);

      this.$store.dispatch("progressStore/set", false);
    },

    async loadValueLists() {
      await this.$store.dispatch("yearsStore/valueList");
      const vldata = {
        id: [27, 30, 35]
      };
      await this.$store.dispatch("valueListsStore/items", vldata);
      await this.$store.dispatch("programsStore/valueList");
    },

    async filter(page) {
      this.filterMenu = false;
      this.selectedID = [];
      this.page = page;
      this.initialize();
    },

    clearFilterColumns() {
      this.d_licenseNumber = "";
      this.d_nameLicense = "";
      this.teacherID = "";
      this.d_nameFirst = "";
      this.d_nameLast = "";
      this.f_followup = "";
      this.noFollowup = "";
      this.f_status = "";
      this.f_month = "";
      this.d_year = "";
      this.d_licenseType = "";
      this.noLicense = "";
      this.dateStart = "";
      this.dateEnd = "";
      this.f_adequate = "";
      this.f_monthSalaryCheck = "";
      this.alert = "";
      this.providerPrekSetting = "";
      this.selectedID = [];
      this.fk_reportID = "";
      this.d_dateApproved = "";
      this.page = 1;
    },

    async changeSectionCheck() {
      await this.loadValueLists();
      if (this.teacherReviewSection !== this.section && !this.fk_reportID) {
        this.clearFilterColumns();
        this.$store.dispatch("teacherReviewStore/clearReviewItems");
      }
      /* Save section in store */
      this.section = this.teacherReviewSection;
    },

    async clearFilter() {
      this.filterMenu = false;
      this.clearFilterColumns();
      this.initialize();
    },

    showFiltered() {
      if (
        this.d_licenseNumber ||
        this.d_nameLicense ||
        this.teacherID ||
        this.d_nameFirst ||
        this.d_nameLast ||
        this.f_followup ||
        this.noFollowup ||
        this.f_status ||
        this.f_status === 0 ||
        this.f_month ||
        this.d_year ||
        this.d_licenseType ||
        this.noLicense ||
        this.dateStart ||
        this.dateEnd ||
        this.f_adequate ||
        this.f_monthSalaryCheck ||
        this.alert ||
        this.providerPrekSetting ||
        this.d_dateApproved
      ) {
        return true;
      }
      return false;
    },

    async openEntry(id) {
      const user = this.$store.getters["usersStore/getAuthUser"];
      if (user.f_teacherReviewEdit === 1) {
        await this.loadValueLists();
        await this.$store.dispatch("teacherReviewStore/entry", id);
      }
    },

    selectDeselectAll() {
      this.$store.dispatch("teacherReviewStore/selectDeselectAll");
    },

    detail(mode, uuid) {
      /* Provider */
      let routeData;
      if (mode === 1) {
        routeData = this.$router.resolve({ name: "provider", params: { providerUUID: uuid } });
      } else if (mode === 2) {
        /* Teacher */
        routeData = this.$router.resolve({ name: "teacher", params: { teacherUUID: uuid } });
      }
      window.open(routeData.href, "_blank");
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
td {
  border: none !important;
}
tbody {
  border-bottom: 1px solid #a9a9a9;
}
</style>
